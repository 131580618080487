import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { Guid } from '@app/models/guid';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-commission-contract-list',
	templateUrl: './commission-contract-list.component.html',
	styleUrls: ['./commission-contract-list.component.css']
})
export class CommissionContractListComponent implements OnInit, OnDestroy {

	//#region Local Variable

	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private allPermissions: any[] = [];
	public pageOptions: PageOptions = new PageOptions();
	private dataOutReqSubscription: Subscription;
	private updateMultiOpenSettlementPeriodsSbsn: Subscription;
	public commissionPermission: string = 'INCENTIVE_COMMISSION_MODIFY';
	public isShowAction: boolean = true;
	private storedFiltersValue: any;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];
	public listOfSettlementPeriodHeaderFilter: any[] = [];
	public listOfStatuses: any[] = [];
	private isCellClickDetail: boolean = false;
	public filterValue: any[] = [];
	public commissionContractDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private guid = new Guid();
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	private updateUserSettingSbsn: Subscription;
	public globalCurrency: string;
	public listOfActions: any[];
	public closeMultipleSettlementProp: any;
	public isShowMultiSettlementPopUp: boolean = false;
	public updateSettlementPeriodBtnText = 'Update Current Settlement Period';
	public isShowLoaderIndicator: boolean;
	public decimalPointForCurrency: number;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public enable_other_currencies: boolean = false;
	//#endregion

	//#region Constructor
	constructor(private _AppCommonSrvc: AppCommonSrvc,
		private _Router: Router,
		private _CommissionService: CommissionService,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _LoaderService: LoaderService,
		private _CustomCurrencyPipe: CustomCurrencyPipe
	) {
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.doLoadCommissionAgreementsState = this.doLoadCommissionAgreementsState.bind(this);
		this.doSaveCommissionAgreementsState = this.doSaveCommissionAgreementsState.bind(this);
		this.doCurrencyCalculateCurrentSettlementCommissionTotal = this.doCurrencyCalculateCurrentSettlementCommissionTotal.bind(this);
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {
		this.preInIt();
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.updateMultiOpenSettlementPeriodsSbsn) {
			this.updateMultiOpenSettlementPeriodsSbsn.unsubscribe();
		}
	}

	// Method used to Init Tab and load data.
	private preInIt() {
		this.listOfSettlementPeriodHeaderFilter = this._CommissionService.getListOfSettlementPeriods;
		this.listOfStatuses = this._AppCommonSrvc.doGetBooleanStatusList();
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.globalCurrency = this._LoginService.globalCurrency;
		this.getAllPermissions();
		this.mainSectionStorageKey = ServerSections.INCENTIVES_LISTING_SETTING;
		this.stateStorageKey = ServerSections.INCENTIVES_COMMISSION_AGREEMENTS_LISTING;
		this.listOfActions = this._CommissionService.getListOfActionsForDatagrid() || [];
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);

		this.storedFiltersValue = [{ dataType: 'string', dataField: 'is_active', searchMode: '=', filterValues: [true] }];
		this.applyStoredFilter();
		this.loadCommissionContractDataSource();
	}

	private getAllPermissions() {
		this.allPermissions = [];
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
		this.isShowAction = true;
		if ((this.allPermissions.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1)) {
			this.isShowAction = false;
		}
	}
	//#endregion

	//#region commission List Methods
	changedCommissionContractStatusAction(commissionGuid, is_active) {
		const view_fields = ['number', 'guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'settlementPeriod', 'commissionPercent', 'subusername'];
		if (this.allPermissions.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			return false;
		}
		this._LoaderService.show();
		const objUpdateData = { 'is_active': is_active };
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('guid', commissionGuid);
		formData.append('updateData', JSON.stringify(objUpdateData));
		formData.append('entity', ServerEntity.COMMISSIONCONTRACTS);
		formData.append('view_fields', JSON.stringify(view_fields));
		this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					this._LoaderService.hide();
					this.loadCommissionContractDataSource();
				} else {
					this._LoaderService.hide();
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public addNewCommissionContract() {
		this._Router.navigate(['/contracts/commission/']);
		this._CommissionService.setEditCommissionGuid('');
		this._CommissionService.setEditCommissionTemplateGuid('');
	}

	public editCommissionContract(data) {
		if (this.isCellClickDetail) {
			return;
		}
		if (!data.is_active) {
			return false;
		}
		this._Router.navigate(['/contracts/commission/']);
		this._CommissionService.setEditCommissionGuid(data.guid);
		this._CommissionService.setEditCommissionTemplateGuid('');
	}

	public copyCommissionContract(data) {
		if (!data.is_active) {
			return false;
		}
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contracttype', 'commissioncontracts');
		formData.append('method', ServerMethods.COPY_CONTRACT);
		formData.append('contractguid', data.guid);
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this.loadCommissionContractDataSource();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#endregion

	//#region Dev_extreme Source

	private loadCommissionContractDataSource() {
		const viewFields = ['number', 'guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'settlementPeriod', 'commissionPercent', 'subusername', 'salesperson_name', 'salesperson_first_name', 'salesperson_last_name', 'settlementPeriodStart', 'settlementPeriodEnd', 'current_settlement_commission_total', 'currency_code'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.COMMISSIONCONTRACTS,
			view_fields: JSON.stringify(viewFields)
		};
		this.commissionContractDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateUpdateCurrentSettlementBtn',
				visible: true,
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.doRefreshDataGrid();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'closeMultipleSettlement',
			// 		text: "Close Multiple settlement",
			// 		onClick: (event: any) => {
			// 			this.doOpenCloseMultipleSettlementPopUp();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'plus',
			// 		text: "Add New",
			// 		onClick: (event: any) => {
			// 			this.addNewCommissionContract()
			// 		}
			// 	}
			// }
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'ADD':
					this.doAddContract();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
				case 'CLOSE_MULTIPLE_SETTLEMENT':
					this.doOpenCloseMultipleSettlementPopUp();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowAction;
		}
	}

	private doAddContract() {
		this.addNewCommissionContract();
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	public doOpenCloseMultipleSettlementPopUp() {
		this.closeMultipleSettlementProp = {
			callFrom: 'commission-contract',
		};
		this.isShowMultiSettlementPopUp = false;
		setTimeout(() => {
			this.isShowMultiSettlementPopUp = true;
		}, 300);
	}
	public doCloseMultipleSettlementPopUp(e: any) {
		this.isShowMultiSettlementPopUp = false;
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Commission_Contract_List.xlsx';
		worksheet = workbook.addWorksheet('Commission List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'settlementPeriod') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'halfYearly' ? 'Bi-annually' : gridCell.value === 'yearly' ? 'Annually' : gridCell.value.toString().charAt(0).toUpperCase() + gridCell.value.toString().slice(1);
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 1000);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 300);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "is_active") {
			this.isCellClickDetail = true;
			return;
		}
	}

	public getListOfStatus(data) {
		this.listOfStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.is_active) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	// #region for Datagrid State Storing functionality

	public doLoadCommissionAgreementsState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveCommissionAgreementsState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion
	public doUpdateCurrentSettlementPeriods() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_MULTIPLE_OPEN_SETTELEMENT_PERIOD);
		formData.append('contracttype', 'commissioncontracts');
		if (this.updateMultiOpenSettlementPeriodsSbsn) {
			this.updateMultiOpenSettlementPeriodsSbsn.unsubscribe();
		}
		this._ToastrService.info('Update Current Settlement Process has been started', 'Info', { closeButton: true, tapToDismiss: true, newestOnTop: true });
		this.isShowLoaderIndicator = true;
		this.updateMultiOpenSettlementPeriodsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this.isShowLoaderIndicator = false;
				if (response.flag) {
					this._ToastrService.success('Update Current Settlement Process has been completed', 'Success', { closeButton: true, tapToDismiss: true, newestOnTop: true });
					this.doRefreshDataGrid();
				}
			}, error: (error) => {
				this.isShowLoaderIndicator = false;
			}
		});
	}

	public doCurrencyCalculateCurrentSettlementCommissionTotal(rowData: any) {
		if (rowData && rowData.hasOwnProperty('currency_code') && this.enable_other_currencies) {
			this.customCurrencyOption.currencyCode = rowData.currency_code;
		} else {
			this.customCurrencyOption.currencyCode = '';
		}
		return this._CommissionService.convertCurrency(rowData.current_settlement_commission_total, this.customCurrencyOption);
	}

	public doCustomizeTotalSummary(e) {
		if (e.value < 0) {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		}
	}
}
