<dx-data-grid #changeOrderDataGridRef id="gridContainer" [dataSource]="changeOrdersDataSource" keyExpr="guid"
	[wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
	[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onInitialized)="initializedChangeOrderDataGrid($event)" (onCellClick)="doOnChangeOrderCellClick($event)"
	(onToolbarPreparing)="doChangeOrderToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling columnRenderingMode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
		[useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-group-panel [visible]="true"></dxo-group-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadChangeOrderState"
		[customSave]="doSaveChangeOrderState" [storageKey]="stateStorageKey">
	</dxo-state-storing>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>
	<dxi-column dataField="ixcode" caption="Prev. Settlement No." dataType="string"></dxi-column>
	<dxi-column dataField="clientName" dataType="string" caption="Customer Name"></dxi-column>
	<dxi-column dataField="invoiceCode" dataType="string" caption="Order #" cellTemplate="cellTemplateDocNo">
		<div *dxTemplate="let element of 'cellTemplateDocNo'">
			<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
		</div>
	</dxi-column>
	<dxi-column dataField="linetotal" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Gross Revenue" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Profit" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="overListRuleRate" dataType="number" [customizeText]="doCustomizeTextToPercent"
		[allowFiltering]="false" [allowSorting]="false" caption="Over List Rate %">
	</dxi-column>
	<dxi-column dataField="overListCommissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Over List Bonus" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="appliedTier" dataType="string" caption="Tier"></dxi-column>
	<dxi-column dataField="commissionRate" dataType="number" [customizeText]="doCustomizeTextToPercent"
		[allowFiltering]="false" [allowSorting]="false" caption="Commission %">
	</dxi-column>
	<dxi-column dataField="previousCommissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Previous Commission" [width]="250" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Current Commission" [width]="250" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="commissionAmountDiff" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Commission Variance" [width]="250" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="salespersonname" dataType="string" [allowHeaderFiltering]="false" caption="Sales Person"
		cellTemplate="cellTemplateSalesPersonName">
		<div *dxTemplate="let element of 'cellTemplateSalesPersonName'">
			{{element.data.salespersonname|| '' }}
		</div>
	</dxi-column>
	<dxo-summary>
		<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"></dxo-value-format>
		</dxi-total-item>
		<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"></dxo-value-format>
		</dxi-total-item>
		<dxi-total-item column="previousCommissionAmount" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"></dxo-value-format>
		</dxi-total-item>
		<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"></dxo-value-format>
		</dxi-total-item>
		<dxi-total-item column="commissionAmountDiff" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"></dxo-value-format>
		</dxi-total-item>
	</dxo-summary>
</dx-data-grid>