import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownButtonComponent } from 'devextreme-angular';
import { Observable, Subscription } from 'rxjs';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { ViewPopUpModel } from '@app/models/popup.model';
@Component({
	selector: 'app-cts-service-credit-memo-rpt-tab',
	templateUrl: './cts-service-credit-memo-rpt-tab.component.html',
	styleUrls: ['./cts-service-credit-memo-rpt-tab.component.css']
})
export class CtsServiceCreditMemoRptTabComponent implements OnInit, OnDestroy {
	public currencyFormat: any;
	@ViewChild("serviceCreditMemoDataGridRef", {}) serviceCreditMemoDataGridRef: DxDataGridComponent;
	@ViewChild("dropDownButtonRef", {}) dropDownButtonRef: DxDropDownButtonComponent;
	@Input() commissionRptDetails: CommissionSettlemntRptModel;
	@Input() viewPopUpModelProps: ViewPopUpModel;
	public pageOptions: PageOptions = new PageOptions();
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public CommissionSettlementReportSectionEnum = CommissionSettlementReportSectionEnum;
	private updateUserSettingSbsn: Subscription;
	public serviceCreditMemosDataSource: any = [];
	public filterValue: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string = '';
	public listOfActions: any[] = [];
	private guid = new Guid();
	private filterValueChangeSbsn: Observable<any>;
	public stateStorageKey = 'serviceCreditMemos-datagrid-state';
	public globalCurrencySymbol: string;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfCommissionBasis: any[] = [];
	public listOfCommissionBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'serviceCreditMemo', text: 'ServiceCreditMemo' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'serviceCreditMemo', text: 'ServiceCreditMemo' }];
	@Input() grdDataSource?: any; // getting from commission contract report 
	public decimalPointForCurrency: number;

	constructor(private _CommissionService: CommissionService,
		private _LoginService: LoginService) {
		this.doLoadServiceCreditMemoState = this.doLoadServiceCreditMemoState.bind(this);
		this.doSaveServiceCreditMemoState = this.doSaveServiceCreditMemoState.bind(this);
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
	}

	ngOnInit(): void {
		this.currencyFormat = this.commissionRptDetails.currencyFormat;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.pageOptions.pageSize = PAGE_SIZE_OPTIONS[1];
		this.listOfActions = this._CommissionService.getListOfActionsDatagrid();
		if (this.commissionRptDetails.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		} else {
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		}
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
		if (this.grdDataSource) {
			this.serviceCreditMemosDataSource = this.grdDataSource;
			this.updateStatesClearBtn();
		} else {
			this.loadServiceCreditMemosDataSource();
		}
		setTimeout(() => {
			if (this.serviceCreditMemoDataGridRef) {
				this.filterValueChangeSbsn = this.serviceCreditMemoDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextTab();
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}
	public doCustomizeTotalSummary(e) {
		if (e.valueText) {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		}
	}
	public doOnCrMemoItemCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				this.viewPopUpModelProps.docNo = element.data[element.column.dataField];
				this.viewPopUpModelProps.docType = 'creditmemo';
				this.viewPopUpModelProps.detailView = '';
				setTimeout(() => {
					this.viewPopUpModelProps.detailView = 'creditmemo';
				}, 300);
			}
		}
	}
	doServiceCreditMemoToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.serviceCreditMemoDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateActionBtns',
			// 	visible: true,
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			// columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	doActionsBtnItemClick(event) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.serviceCreditMemoDataGridRef.instance.showColumnChooser();
					break;
			}
		}
	}
	doRefreshDataGrid() {
		this.serviceCreditMemoDataGridRef.instance.refresh();
	}
	doExportToExcel() {

	}
	doActionsBtnClick(e: any) {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		// }
	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.serviceCreditMemoDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextTab() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		if (this.serviceCreditMemoDataGridRef) {
			this.serviceCreditMemoDataGridRef.filterPanel.customizeText = (e: any) => {
				this.filterText = e.text;
				if (this.filterText) {
					this.isDisabledClearBtn = false;
				} else {
					this.isDisabledClearBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}
	// Method used to Enabled/Disabled Clear Button
	public updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
	public doLoadServiceCreditMemoState = () => {
		return this._CommissionService.loadStateDataGrid(this.stateStorageKey);
	}
	public doSaveServiceCreditMemoState = (e) => {
		this._CommissionService.saveStateDataGrid(e, this.stateStorageKey);
	}

	loadServiceCreditMemosDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.SERVICE_AR_CREDIT_MEMOS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.commissionRptDetails.contractGuid,
		}
		this.serviceCreditMemosDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onLoaded: (result) => {
				this.updateStatesClearBtn();
			}
		});

	}
}