<dx-data-grid #miscDeductruleDataGridRef id="gridContainer" [dataSource]="miscDeductrulesDataSource" keyExpr="guid"
	[wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
	[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onToolbarPreparing)="doMiscDeductruleToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-group-panel [visible]="true"></dxo-group-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<!-- <dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadMiscDeductruleState" [customSave]="doSaveMiscDeductruleState"
		[storageKey]="stateStorageKey">
	</dxo-state-storing> -->
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>
	<dxi-column dataField="commissionType" dataType="string" caption="Commission Type" [width]="'auto'"></dxi-column>
	<dxi-column dataField="condition" dataType="string" caption="Condition" [width]="'auto'"></dxi-column>
	<dxi-column dataField="criterion" dataType="string" caption="Criterion" [width]="'auto'"></dxi-column>
	<dxi-column dataField="linetotal" dataType="number" caption="Commission Sub Total" [width]="'auto'"
		[format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="commissionRate" dataType="number" [format]="doConvertPercentValue" alignment="center"
		caption="Commission Rate (%)" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="commissionAmount" caption="Commission Amount" dataType="number" [width]="'auto'"
		[format]="currencyFormat">
	</dxi-column>
	<dxo-summary>
		<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"> </dxo-value-format>
		</dxi-total-item>
		<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"> </dxo-value-format>
		</dxi-total-item>
	</dxo-summary>
</dx-data-grid>