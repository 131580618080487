
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractsRoutingModule } from './contracts-routing.module';
import { ResharedModule } from '../../reshared/reshared.module';
import { ContractsComponent } from './contracts.component';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomComponentsModule } from '@app/custom-components/custom-components.module';
import { BillbackChargebackContractListComponent } from './billback-chargeback-contract-list/billback-chargeback-contract-list.component';
import { CommissionContractListComponent } from './commission-contract-list/commission-contract-list.component';
import { RebatesContractListComponent } from './rebates-contract-list/rebates-contract-list.component';
import { RoyaltiesContractComponent } from './royalties-contract/royalties-contract.component';
import { RoyaltiesContractListComponent } from './royalties-contract-list/royalties-contract-list.component';
import { MainPipesModule } from 'app/pipes/main-pipes.module';
import { DxSelectBoxModule, DxListModule, DxDataGridModule, DxButtonModule, DxCheckBoxModule, DxDropDownBoxModule, DxHtmlEditorModule, DxDropDownButtonModule, DxTextBoxModule, DxNumberBoxModule, DxRadioGroupModule, DxLookupModule, DxLoadPanelModule, DxTagBoxModule, DxDateBoxModule, DxPopupModule, DxScrollViewModule, DxTabsModule, DxTabPanelModule, DxSortableModule, DxTooltipModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { RoyaltiesClculationViewComponent } from './royalties-contract/royalties-clculation-view/royalties-clculation-view.component';
import { RoyaltiesCloseSettlementListTabComponent } from './royalties-contract/royalties-close-settlement-list-tab/royalties-close-settlement-list-tab.component';
import { CommissionTierbaseContractComponent } from './commission-tierbase-contract/commission-tierbase-contract.component';
import { CommissionTierbaseCloseSettlementListTabComponent } from './commission-tierbase-contract/commission-tierbase-close-settlement-list-tab/commission-tierbase-close-settlement-list-tab.component';
import { CommissionTierbaseExclusionsTabComponent } from './commission-tierbase-contract/commission-tierbase-exclusions-tab/commission-tierbase-exclusions-tab.component';
import { CommissionTierbaseActivitiesTabComponent } from './commission-tierbase-contract/commission-tierbase-activities-tab/commission-tierbase-activities-tab.component';
import { CommissionTierbaseGrowthIncentiveTabComponent } from './commission-tierbase-contract/commission-tierbase-growth-incentive-tab/commission-tierbase-growth-incentive-tab.component';
import { RoyaltiesExclusionsTabComponent } from './royalties-contract/royalties-exclusions-tab/royalties-exclusions-tab.component';
import { CommissionTierbaseRulesTabComponent } from './commission-tierbase-contract/commission-tierbase-rules-tab/commission-tierbase-rules-tab.component';
import { RoyaltiesRulesTabComponent } from './royalties-contract/royalties-rules-tab/royalties-rules-tab.component';
import { BillbackModule } from './billback/billback.module';
import { InputComponentsModule } from 'app/input-components/input-components.module';
import { CommissionTierbaseContractTemplateComponent } from './commission-tierbase-contract-template/commission-tierbase-contract-template.component';
import { CommissionTierbaseContractTemplateListComponent } from './commission-tierbase-contract-template-list/commission-tierbase-contract-template-list.component';
import { CommissionTierbaseContractListTabComponent } from './commission-tierbase-contract-list-tab/commission-tierbase-contract-list-tab.component';
import { CommissionTierbaseCustomerAgeTiersTabComponent } from './commission-tierbase-contract/commission-tierbase-customer-age-tiers-tab/commission-tierbase-customer-age-tiers-tab.component';
import { CommissionTierbaseTemplateExclusionsTabComponent } from './commission-tierbase-contract-template/commission-tierbase-template-exclusions-tab/commission-tierbase-template-exclusions-tab.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CustomNumberDecimalSuffixPipe, CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CustomCurrencyPipe, CustomCurrencySymbolPipe, CustomFormatCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CommissionTierbaseCustomerItemMarginalTabComponent } from './commission-tierbase-contract/commission-tierbase-customer-item-marginal-tab/commission-tierbase-customer-item-marginal-tab.component';
import { CommissionTierbaseSettlementReportComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/commission-tierbase-settlement-report.component';
import { CtsInvoiceRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-invoice-rpt-tab/cts-invoice-rpt-tab.component';
import { CtsOrderRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-order-rpt-tab/cts-order-rpt-tab.component';
import { CtsChangeOrderRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-change-order-rpt-tab/cts-change-order-rpt-tab.component';
import { CtsAmortizedPaymentRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-amortized-payment-rpt-tab/cts-amortized-payment-rpt-tab.component';
import { CtsServiceCreditMemoRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-service-credit-memo-rpt-tab/cts-service-credit-memo-rpt-tab.component';
import { CtsModifiedInvoiceRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-modified-invoice-rpt-tab/cts-modified-invoice-rpt-tab.component';
import { CtsExtraCommBasedruleRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-extra-comm-basedrule-rpt-tab/cts-extra-comm-basedrule-rpt-tab.component';
import { CtsMiscDeductruleRptTabComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/cts-misc-deductrule-rpt-tab/cts-misc-deductrule-rpt-tab.component';
import { RebatesTierbaseSettlementReportComponent } from './rebates-tierbase-contract/rebates-tierbase-settlement-report/rebates-tierbase-settlement-report.component';
import { RoyaltiesSettlementReportComponent } from './royalties-contract/royalties-settlement-report/royalties-settlement-report.component';
import { RsInvoiceRptTabComponent } from './royalties-contract/royalties-settlement-report/rs-invoice-rpt-tab/rs-invoice-rpt-tab.component';
import { RsExtraRylBasedRuleRptTabComponent } from './royalties-contract/royalties-settlement-report/rs-extra-ryl-based-rule-rpt-tab/rs-extra-ryl-based-rule-rpt-tab.component';
import { RsAmortizedPaymentsRptTabComponent } from './royalties-contract/royalties-settlement-report/rs-amortized-payments-rpt-tab/rs-amortized-payments-rpt-tab.component';
import { RsArCreditMemosRptTabComponent } from './royalties-contract/royalties-settlement-report/rs-ar-credit-memos-rpt-tab/rs-ar-credit-memos-rpt-tab.component';

@NgModule({
	declarations: [
		ContractsComponent,
		BillbackChargebackContractListComponent,
		CommissionContractListComponent,
		RebatesContractListComponent,
		RoyaltiesContractComponent,
		RoyaltiesContractListComponent,
		RoyaltiesExclusionsTabComponent,
		RoyaltiesClculationViewComponent,
		RoyaltiesCloseSettlementListTabComponent,
		CommissionTierbaseContractComponent,
		CommissionTierbaseCloseSettlementListTabComponent,
		CommissionTierbaseExclusionsTabComponent,
		CommissionTierbaseActivitiesTabComponent,
		CommissionTierbaseGrowthIncentiveTabComponent,
		CommissionTierbaseRulesTabComponent,
		RoyaltiesRulesTabComponent,
		CommissionTierbaseContractTemplateComponent,
		CommissionTierbaseContractTemplateListComponent,
		CommissionTierbaseContractListTabComponent,
		CommissionTierbaseCustomerAgeTiersTabComponent,
		CommissionTierbaseTemplateExclusionsTabComponent,
		CommissionTierbaseCustomerItemMarginalTabComponent,
		CommissionTierbaseSettlementReportComponent,
		CtsInvoiceRptTabComponent,
		CtsOrderRptTabComponent,
		CtsChangeOrderRptTabComponent,
		CtsAmortizedPaymentRptTabComponent,
		CtsServiceCreditMemoRptTabComponent,
		CtsModifiedInvoiceRptTabComponent,
		CtsExtraCommBasedruleRptTabComponent,
		CtsMiscDeductruleRptTabComponent,
		RebatesTierbaseSettlementReportComponent,
		RoyaltiesSettlementReportComponent,
		RsInvoiceRptTabComponent,
		RsExtraRylBasedRuleRptTabComponent,
		RsAmortizedPaymentsRptTabComponent,
		RsArCreditMemosRptTabComponent
	],
	imports: [
		CommonModule,
		InputComponentsModule,
		ContractsRoutingModule,
		ResharedModule,
		FormsModule,
		ReactiveFormsModule,
		MatMenuModule,
		MatIconModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatSortModule,
		MatTableModule,
		MatPaginatorModule,
		CustomComponentsModule,
		NgxPermissionsModule,
		MatTooltipModule,
		MainPipesModule,
		DxSelectBoxModule,
		DxListModule,
		DxDataGridModule,
		DxButtonModule,
		DxCheckBoxModule,
		DxDropDownBoxModule,
		DxHtmlEditorModule,
		DxTextBoxModule,
		DxTagBoxModule,
		DxDropDownButtonModule,
		DxNumberBoxModule,
		DxLookupModule,
		DxLoadPanelModule,
		DxRadioGroupModule,
		DxTagBoxModule,
		DxDateBoxModule,
		DxPopupModule,
		DxScrollViewModule,
		DxTabsModule,
		DxTabPanelModule,
		DxSortableModule,
		NgxFileDropModule,
		DxTooltipModule,
		DxLoadIndicatorModule
	],
	providers: [
		AuthGuardService,
		CurrencyPipe,
		DecimalPipe,
		DatePipe,
		CustomNumberDecimalSuffixPipe,
		CustomCurrencySymbolPipe,
		CustomFormatCurrencyPipe,
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	],

	exports: [
		CtsInvoiceRptTabComponent,
		CtsOrderRptTabComponent,
		CtsChangeOrderRptTabComponent,
		CtsAmortizedPaymentRptTabComponent,
		CtsServiceCreditMemoRptTabComponent,
		CtsModifiedInvoiceRptTabComponent,
		CtsExtraCommBasedruleRptTabComponent,
		CtsMiscDeductruleRptTabComponent,
		BillbackModule
	]
})
export class ContractsModule { }
