import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { RoyaltiesSettlementReportSectionEnum, ContractTypeValEnum } from '@app/constants-enums/enums';
import { RoyaltiesSettlemntRptModel } from '@app/models/royalties-contract.model';
import { ViewInvoiceDetailPopUpModel, ViewPopUpModel } from '@app/models/popup.model';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RsInvoiceRptTabComponent } from './rs-invoice-rpt-tab/rs-invoice-rpt-tab.component';
import { RsArCreditMemosRptTabComponent } from './rs-ar-credit-memos-rpt-tab/rs-ar-credit-memos-rpt-tab.component';
import { RsExtraRylBasedRuleRptTabComponent } from './rs-extra-ryl-based-rule-rpt-tab/rs-extra-ryl-based-rule-rpt-tab.component';
import { RsAmortizedPaymentsRptTabComponent } from './rs-amortized-payments-rpt-tab/rs-amortized-payments-rpt-tab.component';
import { CellRange, exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { jsPDF } from 'jspdf';
import saveAs from 'file-saver';
import { CurrencyOptions } from '@app/models/common.model';
import { proxima_nova, proxima_nova_bold, proxima_nova_semi_bold } from '@app/services/app-common-srvc/app-common-fonts.service';
import moment from 'moment';
import { RoyaltiesService } from '@app/services/contract-service/royalties/royalties.service';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-royalties-settlement-report',
	templateUrl: './royalties-settlement-report.component.html',
	styleUrls: ['./royalties-settlement-report.component.css']
})
export class RoyaltiesSettlementReportComponent implements OnInit, OnDestroy, AfterViewInit {
	private dataOutReqSubscription: Subscription;
	private dataOutAllSecReqSubscription: Subscription;
	private getCommssionReportExportDataSbsn: Subscription;
	private doDownloadPdfSbsn: Subscription;
	public royaltiesRptTabs: any[] = [];
	public currentRptTab: string;
	public RoyaltiesSettlementReportSectionEnum = RoyaltiesSettlementReportSectionEnum;
	public royaltiesRptDetails: RoyaltiesSettlemntRptModel;
	public viewPopUpModelProps: ViewPopUpModel = new ViewPopUpModel();
	public royaltiesSatusUIClass: any;
	public enable_amortise_payments_for_royalties: boolean = false;
	public enable_incremental_payment: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	public isShowExportPdf: boolean;
	public listOfActions: any[] = [];
	public isShowLoaderIndicator: boolean = false;
	public reloadAllDatagrid: boolean = true;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	@ViewChild(RsInvoiceRptTabComponent) rsInvoiceRptTabCom!: RsInvoiceRptTabComponent;
	@ViewChild(RsArCreditMemosRptTabComponent) rsArCreditMemosRptTabCom!: RsArCreditMemosRptTabComponent;
	@ViewChild(RsAmortizedPaymentsRptTabComponent) rsAmortizedPaymentsRptTabCom!: RsAmortizedPaymentsRptTabComponent;
	@ViewChild(RsExtraRylBasedRuleRptTabComponent) rsExtraRylBasedRuleRptTabCom!: RsExtraRylBasedRuleRptTabComponent;

	public indexTab: number = 0;
	constructor(
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _RoyaltiesService: RoyaltiesService,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,

	) { }

	ngOnInit(): void {
		this.enable_amortise_payments_for_royalties = false;
		this.royaltiesRptTabs = this._RoyaltiesService.getRoyaltiesSttlementReportTab();
		this.royaltiesRptDetails = this._RoyaltiesService.getRoyaltiesRptDetails();
		this.listOfActions = this._RoyaltiesService.getListOfActionsForRoyaltiesReport() || [];;
		this.royaltiesSatusUIClass = {
			'orangeInvoice': this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Processing',
			'purpleInvoice': this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Open',
			'redInvoice': this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Error',
			'greenInvoice': this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Success'
		};
		this.customCurrencyOption = this._RoyaltiesService.getCustomCurrencyOption(this.royaltiesRptDetails);
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_amortise_payments')) {
			const enable_amortise_payments = this._LoginService.loginUser.account_detail.enable_amortise_payments;
			if (enable_amortise_payments) {
				if (this._LoginService.loginUser.account_detail.hasOwnProperty('amortise_payment_use_for')) {
					const amortisePaymentUseFor = this._LoginService.loginUser.account_detail.amortise_payment_use_for
					const royaltiesIndex = amortisePaymentUseFor.findIndex(u => u === 'royalties' || 'royalty');
					if (royaltiesIndex !== -1) {
						this.enable_amortise_payments_for_royalties = true;
					}
				}
			}
		}
		this.enable_incremental_payment = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_incremental_payment')) {
			this.enable_incremental_payment = this._LoginService.loginUser.account_detail.enable_incremental_payment;
		}
		this.enable_profit_on_document_header = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_profit_on_document_header')) {
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
		}
		this.isShowExportPdf = false;
		if (this.royaltiesRptDetails.apInvoiceCreatedInERP !== 'Open') {
			this.isShowExportPdf = true;
		}
		this.loadRptTab();
		this.getAllSectionTotal();
	}

	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataOutAllSecReqSubscription) {
			this.dataOutAllSecReqSubscription.unsubscribe();
		}
		if (this.getCommssionReportExportDataSbsn) {
			this.getCommssionReportExportDataSbsn.unsubscribe();
		}
		if (this.doDownloadPdfSbsn) {
			this.doDownloadPdfSbsn.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		// this.reloadAllDatagrid = false;
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code === 'EXPORT_PDF');
		const closeCurrSettIndex = this.listOfActions.findIndex(i => i.btn_code === 'CLOSE_CURRENT_SETTLEMENT_PERIOD');
		const updateCurrSettIndex = this.listOfActions.findIndex(i => i.btn_code === 'UPDATE_CURRENT_SETTLEMENT');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].visible = this.isShowExportPdf;
		}
		if (closeCurrSettIndex !== -1) {
			this.listOfActions[closeCurrSettIndex].visible = this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Open' ? true : false;
		}
		if (updateCurrSettIndex !== -1) {
			this.listOfActions[updateCurrSettIndex].visible = this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Open' ? true : false;
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				// case 'CLOSE_CURRENT_SETTLEMENT_PERIOD':
				// 	this.doActionCloseCurrentSettlementPeriod();
				// 	break;
				case 'UPDATE_CURRENT_SETTLEMENT':
					this.doActionUpdateCurrentSettlement();
					break;
				case 'EXPORT_PDF':
					this.doExporToPDF();
					break;
				case 'EXPORT':
					this.onExporting();
					break;
			}
		}
	}

	doOnTabOptionChanged(e) {
		//console.log('EEEE ', e);
		if (e.name === "changedProperty") {

		}
	}

	public doSelectionChanged(e) {
		if (e.addedItems && e.addedItems.length > 0) {
			let selectedItem = e.addedItems[0];
			if (selectedItem.key === RoyaltiesSettlementReportSectionEnum.INVOICES) {
				this.rsInvoiceRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES) {
				this.rsExtraRylBasedRuleRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS) {
				this.rsAmortizedPaymentsRptTabCom.updateStatesClearBtn();
			}
			// if (selectedItem.key === RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS) {
			// 	this.rsArCreditMemosRptTabCom.updateStatesClearBtn();
			// }
		}
	}

	private addFontToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova.ttf';
		const semiBoldFont = 'proxima_nova semiBold.ttf';
		const boldFont = 'proxima_nova Bold.ttf';
		doc.addFileToVFS(regularFont, proxima_nova);
		doc.addFileToVFS(semiBoldFont, proxima_nova_semi_bold);
		doc.addFileToVFS(boldFont, proxima_nova_bold);
		doc.addFont(regularFont, 'proxima_nova', 'normal');
		doc.addFont(semiBoldFont, 'proxima_nova semiBold', 'normal');
		doc.addFont(boldFont, 'proxima_nova Bold', 'bold');
	}

	private addPageNumberToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova';
		const pageCount = doc.getNumberOfPages();
		doc.setFont(regularFont, 'normal');
		doc.setFontSize(9);
		for (var i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, {
				align: 'center'
			});
		}
	}

	private addHeaderToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';
		const pageWidth = doc.internal.pageSize.getWidth();
		const pageHeight = doc.internal.pageSize.getHeight();
		const pageCount = doc.getNumberOfPages();
		let invoiceOrOrderTotal = 0;
		let companyName = '';
		let companyImageBase64 = '';
		let fileName = '';
		if (this._LoginService.loginUser.account_detail.company_name) {
			companyName = this._LoginService.loginUser.account_detail.company_name;
		}
		if (this._LoginService.loginUser.account_detail.companylogo) {
			companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}
		for (let i = 1; i <= pageCount; i++) {
			let intialAxisX = 5;
			const intialAxisY = 15;
			let incrementalAxisY = 15;
			doc.setPage(i);
			const royaltiesReport = 'Royalty Report';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(18);
			doc.text(royaltiesReport, intialAxisX, incrementalAxisY);

			incrementalAxisY += 7; // 22;
			const contractName = this.royaltiesRptDetails.contractName;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(18);
			doc.text(contractName, intialAxisX, incrementalAxisY);

			const fontSizeForSubRow = 12;
			let isSalesRep = false;
			if (this.royaltiesRptDetails.subusername) {
				isSalesRep = true;
				// Sales Rep :
				incrementalAxisY += 10;
				const salesRepLabel = 'Vendor Name: ';
				doc.setFontSize(fontSizeForSubRow);
				doc.setFont(regularFont, 'normal');
				doc.text(salesRepLabel, intialAxisX, incrementalAxisY);
				let row0Xaxis = intialAxisX + doc.getStringUnitWidth(salesRepLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

				const salesRepValue = this.royaltiesRptDetails.selectVendor;
				doc.setFontSize(fontSizeForSubRow);
				doc.setFont(boldFont, 'bold');
				doc.text(salesRepValue, row0Xaxis, incrementalAxisY);
			}

			// Generated: DATETIME
			incrementalAxisY += isSalesRep ? 6 : 10; // 34
			const generatedLabel = 'Generated: ';
			doc.setFontSize(fontSizeForSubRow);
			doc.setFont(regularFont, 'normal');
			doc.text(generatedLabel, intialAxisX, incrementalAxisY);
			let row1Xaxis = intialAxisX + doc.getStringUnitWidth(generatedLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const generatedValue = moment().format('YYYY-MM-DD | hh:mm:ss A');
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(generatedValue, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(generatedValue) * fontSizeForSubRow / doc.internal.scaleFactor;

			// Settlement Period: : DATE TO DATE
			const settlementPeriodLabel = 'Settlement Period: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			row1Xaxis += 10;
			doc.text(settlementPeriodLabel, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(settlementPeriodLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const settlementPeriodValue = this.royaltiesRptDetails.settlementPeriodStartDate + ' To ' + this.royaltiesRptDetails.settlementPeriodEndDate;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(settlementPeriodValue, row1Xaxis, incrementalAxisY);

			// Royalties : $00.00
			incrementalAxisY += 6; // 40
			const royaltiesText = 'Current Royalty: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(royaltiesText, intialAxisX, incrementalAxisY);
			let row2Xaxis = intialAxisX + doc.getStringUnitWidth(royaltiesText) * fontSizeForSubRow / doc.internal.scaleFactor;

			const royaltiesValueText = this._RoyaltiesService.convertCurrency(invoiceOrOrderTotal || 0, this.customCurrencyOption);
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(royaltiesValueText, row2Xaxis, incrementalAxisY);
			row2Xaxis += doc.getStringUnitWidth(royaltiesValueText) * fontSizeForSubRow / doc.internal.scaleFactor;


			if (companyImageBase64) {
				const imageWidth = 40;
				const imageHeight = 18;
				const imageXPosition = (pageWidth - imageWidth) - intialAxisX;
				const imageYPosition = intialAxisY;
				const imageProperties = doc.getImageProperties(companyImageBase64);
				doc.addImage(companyImageBase64, imageProperties.fileType.toLowerCase(), imageXPosition, imageYPosition, imageWidth, imageHeight);
			} else {
				const companyNameLabel = this._LoginService.loginUser.account_detail.company_name;
				doc.setFont(boldFont, 'bold');
				doc.setFontSize(18);
				doc.text(companyNameLabel, pageWidth - intialAxisX, intialAxisY, { align: 'right' });
			}
		}
	}

	private doCustomizeCell(gridCell: any, pdfCell: any) {
		pdfCell.borderColor = '#C4C4C4';
		pdfCell.drawLeftBorder = false;
		pdfCell.drawRightBorder = false;
		pdfCell.borderWidth = 0.5;
		pdfCell.textColor = '#000';
		pdfCell.wordWrapEnabled = true;
	}


	private doCustomizeDrawCell(gridCell: any, pdfCell: any) {
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';

		if (gridCell.rowType === 'header') {
			pdfCell.font.name = boldFont;
			pdfCell.font.style = 'bold';
			pdfCell.font.size = 9;
		}
		if (gridCell.rowType === 'data') {
			pdfCell.font.name = regularFont;
			pdfCell.font.style = 'normal';
			pdfCell.font.size = 9;
		}
		if (gridCell.rowType === 'totalFooter') {
			pdfCell.font.name = boldFont;
			pdfCell.font.style = 'bold';
			pdfCell.font.size = 9;
		}
	}

	public doExporToPDF() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DOWNLOAD_CONTRACT_STATEMENT);
		formData.append('guid', this.royaltiesRptDetails.contractGuid);
		formData.append('contracttype', 'royaltiescontracts');

		if (this.doDownloadPdfSbsn) {
			this.doDownloadPdfSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.doDownloadPdfSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					const fileLink = response.data.file_link || '';
					if (fileLink) {
						const iframe = '<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="' + fileLink + '" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>';

						let win: any = window.open('', '_blank', 'toolbar=no,location=no,menubar=no,resizable=yes,height=720,width=1080,scrollbars=yes,status=yes');
						win.document.write(iframe);
					}
				}
			}
			, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#region  export PDF manual
	doExportJsPdf() {
		const pdfDoc = new jsPDF('landscape', 'mm', 'legal');
		let fileName = '';
		if (this.royaltiesRptDetails?.apInvoiceNumber) {
			fileName = this.royaltiesRptDetails.apInvoiceNumber + '_';
		}
		if (this.royaltiesRptDetails?.subusername) {
			fileName = this.royaltiesRptDetails.subusername + '_';
		}
		fileName = fileName + 'Royalty_Report.pdf';
		let arrayGridInstance: any[] = [];
		let tabNames: any[] = [];
		this.indexTab = 0;
		if (this.royaltiesRptTabs.length > 0) {
			this.royaltiesRptTabs.forEach(element => {
				arrayGridInstance.push(this.getDataGridInstance(element.key));
				tabNames.push(element.title);
			});
			this.recursiveAddGridToPdf(arrayGridInstance, pdfDoc, tabNames).then(() => {
				//if(this.indexTab)
				this.addHeaderToJsPdf(pdfDoc);
				this.addPageNumberToJsPdf(pdfDoc);
				pdfDoc.save(fileName);
			}
			)

		}
	}
	recursiveAddGridToPdf(grid, pdfDoc, tabNames) {
		const nextGrid = grid.shift();
		if (nextGrid) {
			return this.AddGridToPDF(pdfDoc, nextGrid, tabNames).then(_ => this.recursiveAddGridToPdf(grid, pdfDoc, tabNames))
		} else {
			return Promise.resolve();
		}

	}
	AddGridToPDF(pdfDoc, grid, tabNames) {
		const context = this;
		if (this.indexTab > 0) {
			pdfDoc.addPage();
		}
		const lastPoint = { x: 0, y: 0 };
		this.addFontToJsPdf(pdfDoc);
		const semiBoldFont = 'proxima_nova semiBold';
		let intialAxisX = 5;
		const intialAxisY = 15;
		let incrementalAxisY = 0;
		incrementalAxisY = 0;
		incrementalAxisY += 55;
		const tabLabel = tabNames[this.indexTab];
		pdfDoc.setFont(semiBoldFont, 'normal');
		pdfDoc.setFontSize(15);
		pdfDoc.text(tabLabel, intialAxisX, incrementalAxisY);
		incrementalAxisY += 5;
		// var options = {
		// 	jsPDFDocument: pdfDoc,
		// 	component: grid,
		// 	autoTableOptions: {
		// 		margin: { left: x },
		// 		startY: y,
		// 		tableWidth: tableWidth
		// 	}
		// };
		let tabObj =
		{
			jsPDFDocument: pdfDoc,
			component: grid,
			margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
			repeatHeaders: true,
			customizeCell(options) {
				const { gridCell, pdfCell } = options;
				context.doCustomizeCell(gridCell, pdfCell);
				if (gridCell.rowType === 'data' && gridCell.column.dataField === 'eligible_for_royalties') {
					pdfCell.text = gridCell.value ? 'Yes' : 'No';
				}
			},
			customDrawCell(options) {
				const { gridCell, pdfCell, rect } = options;
				context.doCustomizeDrawCell(gridCell, pdfCell);
				if (context.indexTab == 0) {
					if (lastPoint.x < rect.x + rect.w) {
						lastPoint.x = rect.x + rect.w;
					}
					if (lastPoint.y < rect.y + rect.h) {
						lastPoint.y = rect.y + rect.h;
					}
				}
			},
			onRowExporting(options) {
				const { rowCells, rowHeight } = options;
			},
		}
		this.indexTab++;
		return exportDataGridPdf(tabObj);
	}
	//#endregion  export PDF manual

	onExporting() {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let companyName = '';
		let companyImageBase64 = '';
		let fileName = '';
		if (this._LoginService.loginUser.account_detail.company_name) {
			companyName = this._LoginService.loginUser.account_detail.company_name;
		}
		if (this._LoginService.loginUser.account_detail.companylogo) {
			companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}
		if (this.royaltiesRptDetails?.apInvoiceNumber) {
			fileName = this.royaltiesRptDetails.apInvoiceNumber + '_';
		}
		if (this.royaltiesRptDetails?.subusername) {
			fileName = this.royaltiesRptDetails.subusername + '_';
		}
		fileName = fileName + 'Royalty_Report.xlsx';
		if (this.royaltiesRptTabs.length > 0) {
			for (let index = 0; index < this.royaltiesRptTabs.length; index++) {
				const tabSheet = workbook.addWorksheet(this.royaltiesRptTabs[index].title);
				let tabSheetObj =
				{
					worksheet: tabSheet,
					component: context.getDataGridInstance(this.royaltiesRptTabs[index].key),
					keepColumnWidths: true,
					autoFilterEnabled: false,
				}

				if (index === 0) {
					tabSheetObj['topLeftCell'] = { row: 12, column: 1 }; // skip first 12 row
					// add company logo
					tabSheet.mergeCells(1, 1, 3, 2);
					if (companyImageBase64) {
						const image = workbook.addImage({
							base64: companyImageBase64,
							//ext: { width: 500, height: 200 },
							extension: 'png',
							editAs: 'oneCell'
						});
						tabSheet.addImage(image, 'A1:B2');
					} else {
						tabSheet.getRow(1).getCell(1).font = { size: 20, bold: true };
						tabSheet.getRow(1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
						tabSheet.getRow(1).getCell(1).value = companyName;
					}
					// customize cell as per original value
					tabSheetObj['customizeCell'] = (options) => {
						const { gridCell, excelCell } = options;
						if (gridCell.rowType === "data") {
							if (gridCell.column.dataField === 'document_type') {
								const listOfDocTypeHeaderFilter = this.rsInvoiceRptTabCom.listOfDocTypeHeaderFilter;
								const indexDT = listOfDocTypeHeaderFilter.findIndex(i => i.value === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfDocTypeHeaderFilter[indexDT].text;
								}
							}
							if (gridCell.column.dataField === 'uomcode' || gridCell.column.dataField === 'contract_selectedUom') {
								const listOfUoms = this.rsInvoiceRptTabCom.listOfUoms;
								const indexDT = listOfUoms.findIndex(i => i.code === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfUoms[indexDT].name;
								}
							}
							if (gridCell.column.dataField === 'tier_basis') {
								const listOfTierBasis = this.rsInvoiceRptTabCom.listOfTierBasis;
								const indexDT = listOfTierBasis.findIndex(i => i.code === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfTierBasis[indexDT].label;
								}
							}

							if (gridCell.column.dataField === 'royalties_basis') {
								const listOfRoyaltiesBasis = this.rsInvoiceRptTabCom.listOfCommissionBasis;
								const indexDT = listOfRoyaltiesBasis.findIndex(i => i.code === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfRoyaltiesBasis[indexDT].label;
								}
							}

						}
					}
					//End customize cell as per original value
				}

				if (this.royaltiesRptTabs[index].key === this.RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS) {
					// customize cell as per original value
					tabSheetObj['customizeCell'] = (options) => {
						const { gridCell, excelCell } = options;
						if (gridCell.rowType === "data") {
							if (gridCell.column.dataField === 'document_type') {
								const listOfDocTypeHeaderFilter = this.rsInvoiceRptTabCom.listOfDocTypeHeaderFilter;
								const indexDT = listOfDocTypeHeaderFilter.findIndex(i => i.value === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfDocTypeHeaderFilter[indexDT].text;
								}
							}
						}
					}
				}
				exportDataGrid(tabSheetObj)
					.then(() => {
						if (index === 0) {
							// Excel Summary View
							const firstRow = tabSheet.getRow(5);
							const statusColor = this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Processing' ? 'F99301' :
								this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Open' ? '706cce' :
									this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Error' ? 'FE5059' :
										this.royaltiesRptDetails.apInvoiceCreatedInERP === 'Success' ? '02C19F' : '';
							firstRow.height = 20;
							tabSheet.mergeCells('A5:B5');
							tabSheet.mergeCells('C5:H5');
							firstRow.getCell(1).font = { size: 14 };
							firstRow.getCell(1).value = 'Royalty Report: ';
							firstRow.getCell(3).font = { size: 16, bold: true };
							firstRow.getCell(3).value = this.royaltiesRptDetails.contractName;
							firstRow.getCell(12).font = { size: 14 };
							firstRow.getCell(12).value = 'Status ';
							firstRow.getCell(13).value = this.royaltiesRptDetails.apInvoiceCreatedInERP;
							firstRow.getCell(13).font = { size: 14, color: { argb: statusColor }, bold: true };
							const secondRow = tabSheet.getRow(6);
							secondRow.height = 20;
							tabSheet.mergeCells('A6:B6');
							tabSheet.mergeCells('C6:H6');
							secondRow.getCell(1).font = { size: 14 };
							secondRow.getCell(1).value = 'Vendor Name: ';
							secondRow.getCell(3).font = { size: 14 };
							secondRow.getCell(3).value = this.royaltiesRptDetails.selectVendor;
							secondRow.getCell(12).font = { size: 14 };
							secondRow.getCell(12).value = 'Generated: ';
							secondRow.getCell(13).font = { size: 14 };
							secondRow.getCell(13).value = this.royaltiesRptDetails.created_date;
							tabSheet.mergeCells('A7:B7');
							tabSheet.mergeCells('C7:H7');
							const thirdRow = tabSheet.getRow(7);
							thirdRow.getCell(1).font = { size: 14 };
							thirdRow.getCell(1).value = 'Settlement Period: ';
							thirdRow.getCell(3).font = { size: 14 };
							thirdRow.getCell(3).value = this.royaltiesRptDetails.settlementPeriodStartDate + ' To ' + this.royaltiesRptDetails.settlementPeriodEndDate;
							const fifthRow = tabSheet.getRow(9);
							const sixthRow = tabSheet.getRow(10);
							if (this.royaltiesRptTabs.length > 0) {
								for (let index = 0; index < this.royaltiesRptTabs.length; index++) {
									const element = this.royaltiesRptTabs[index];
									fifthRow.getCell(1 + index).value = element.title;
									fifthRow.getCell(1 + index).font = { size: 14 };
									sixthRow.getCell(1 + index).value = this._RoyaltiesService.convertCurrency(element.value, this.customCurrencyOption);
									sixthRow.getCell(1 + index).font = { size: 14, bold: true };
								}
							}
							tabSheet.mergeCells('H9:J9');
							tabSheet.mergeCells('H10:J10');
							tabSheet.mergeCells('K9:M9');
							tabSheet.mergeCells('K10:M10');
							fifthRow.getCell(10).font = { size: 14 };
							fifthRow.getCell(10).value = 'Total Royalty Payable';
							fifthRow.getCell(12).font = { size: 14 };
							fifthRow.getCell(12).value = 'Total Royalty Generated'
							sixthRow.getCell(10).font = { size: 14, bold: true };
							sixthRow.getCell(10).value = this._RoyaltiesService.convertCurrency(this.royaltiesRptDetails.totalRoyaltiesPayable, this.customCurrencyOption);
							sixthRow.getCell(12).font = { size: 14, bold: true };
							sixthRow.getCell(12).value = this._RoyaltiesService.convertCurrency(this.royaltiesRptDetails.totalRoyaltiesGenerated, this.customCurrencyOption);
							tabSheet.getCell('H9').border = {
								top: { style: 'thin', color: { argb: '05C1A0' } },
								left: { style: 'thin', color: { argb: '05C1A0' } },
							};
							tabSheet.getCell('K9').border = {
								top: { style: 'thin', color: { argb: '05C1A0' } },
								right: { style: 'thin', color: { argb: '05C1A0' } }
							};
							tabSheet.getCell('H10').border = {
								bottom: { style: 'thin', color: { argb: '05C1A0' } },
								left: { style: 'thin', color: { argb: '05C1A0' } },
							};
							tabSheet.getCell('K10').border = {
								bottom: { style: 'thin', color: { argb: '05C1A0' } },
								right: { style: 'thin', color: { argb: '05C1A0' } }
							};
							tabSheet.getCell('H9').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}
							tabSheet.getCell('K9').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}
							tabSheet.getCell('H10').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}
							tabSheet.getCell('K10').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}

							// End Excel Summary View
						} else {
							return exportDataGrid(tabSheetObj);
						}
					})
					//last index export file 
					.then(() => {
						if (index === (this.royaltiesRptTabs.length - 1)) {
							workbook.xlsx.writeBuffer().then(function (buffer) {
								saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
							})
						}
					}
					)
			}
		} else {
			this._ToastrService.info('Data Not Found !', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
	}

	getDataGridInstance(tabKey) {
		let returnIns: any;
		switch (tabKey) {
			case RoyaltiesSettlementReportSectionEnum.INVOICES:
				const invoiceDataGridIns = this.rsInvoiceRptTabCom.invoiceDataGridRef.instance;
				returnIns = invoiceDataGridIns;
				break;
			case RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS:
				const amortizedPaymentDataGridIns = this.rsAmortizedPaymentsRptTabCom.amortizedPaymentDataGridRef.instance;
				returnIns = amortizedPaymentDataGridIns;
				break;
			case RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES:
				const extraCommBasedruleDataGridIns = this.rsExtraRylBasedRuleRptTabCom.ExtraCommBasedruleDataGridRef.instance;
				returnIns = extraCommBasedruleDataGridIns;
				break;
			// case RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS:
			// 	const serviceCreditMemoDataGridIns = this.rsArCreditMemosRptTabCom.serviceCreditMemoDataGridRef.instance;
			// 	returnIns = serviceCreditMemoDataGridIns;
			// 	break;
			// default:
			// 	break;
		}
		return returnIns;
	}

	exportBackendRequest() {
		let viewTabSection: any[] = []
		if (this.royaltiesRptTabs.length > 0) {
			this.royaltiesRptTabs.forEach(elementTab => {
				viewTabSection.push(elementTab.section);
			});
		}
		if (viewTabSection.length == 0) {
			this._ToastrService.info('Data Not Found !', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.AP_INVOICE_REPORT);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('contractguid', this.royaltiesRptDetails.contractGuid);
		formData.append('contracttype', ContractTypeValEnum.ROYALTIESCONTRACTS);
		formData.append('section', JSON.stringify(viewTabSection));
		formData.append('action', 'export');
		if (this.getCommssionReportExportDataSbsn) {
			this.getCommssionReportExportDataSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getCommssionReportExportDataSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();

				if (response && response.flag) {

				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#region close settlement period

	doActionCloseCurrentSettlementPeriod() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Close Settlement',
			message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				if (this.royaltiesRptDetails.contractGuid !== undefined) {
					// this.calculateSettlementPeriod();
					this.callCloseSettlementReq();
				}
			}
		});
	}

	callCloseSettlementReq() {
		const formData = new FormData();
		this._LoaderService.show();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.CLOSE_CURRENT_SETTELEMENT);
		formData.append('contractguid', this.royaltiesRptDetails.contractGuid);
		formData.append('contracttype', ContractTypeValEnum.ROYALTIESCONTRACTS);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response.data) {
							const closeSettlementData = response.data['closesettlement'];
							if (closeSettlementData && closeSettlementData.length > 0) {
								this.royaltiesRptDetails.ixcode = closeSettlementData[0]['ixcode'];
								this.royaltiesRptDetails.apInvoiceCreatedInERP = closeSettlementData[0]['apInvoiceCreatedInERP'];
								this.royaltiesRptDetails.created_date = closeSettlementData[0]['created_date'];
								this.royaltiesRptDetails.settlementValue = closeSettlementData[0]['settlementValue'];
								this.royaltiesRptDetails.totalRoyaltiesGenerated = closeSettlementData[0]['totalRoyaltiesGenerated'];
								this.ngOnInit();
							}
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				console.log('ERROR ::', error)
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#endregion

	//#region update settlement period
	doActionUpdateCurrentSettlement() {
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
		formData.append('contractguid', this.royaltiesRptDetails.contractGuid);
		formData.append('contracttype', ContractTypeValEnum.ROYALTIESCONTRACTS);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag && response.data) {
						if (response.data['settlementValue']) {
							this.royaltiesRptDetails.settlementValue = response.data['settlementValue'];
						}
						if (response.data['totalRoyaltiesGenerated']) {
							this.royaltiesRptDetails.totalRoyaltiesGenerated = response.data['totalRoyaltiesGenerated'];
						}
						this.ngOnInit();
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				console.log('ERROR ::', error)
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	//#endregion

	public loadRptTab() {
		this.royaltiesRptTabs = this._RoyaltiesService.getRoyaltiesSttlementReportTab();

		if (!this.enable_amortise_payments_for_royalties) {
			const indexAmortisePayment = this.royaltiesRptTabs.findIndex(i => i.key === RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS);
			if (indexAmortisePayment !== -1) {
				this.royaltiesRptTabs.splice(indexAmortisePayment, 1);
			}
		}

		this.refreshFilterValue();
	}

	refreshFilterValue() {
		if (this.rsInvoiceRptTabCom) {
			this.rsInvoiceRptTabCom.updateStatesClearBtn();
		}
		if (this.rsAmortizedPaymentsRptTabCom) {
			this.rsAmortizedPaymentsRptTabCom.updateStatesClearBtn();
		}
		if (this.rsExtraRylBasedRuleRptTabCom) {
			this.rsExtraRylBasedRuleRptTabCom.updateStatesClearBtn();
		}
		// if (this.rsArCreditMemosRptTabCom) {
		// 	this.rsArCreditMemosRptTabCom.updateStatesClearBtn();
		// }
	}

	clickRptTab(tabKey) {
		this.currentRptTab = tabKey;
	}

	public getAllSectionTotal() {
		const formData = new FormData();
		this.isShowLoaderIndicator = true;
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.AP_INVOICE_REPORT);
		formData.append('section', 'all');
		formData.append('contracttype', ContractTypeValEnum.ROYALTIESCONTRACTS);
		formData.append('contractguid', this.royaltiesRptDetails.contractGuid);
		if (this.dataOutAllSecReqSubscription) {
			this.dataOutAllSecReqSubscription.unsubscribe();
		}
		this.dataOutAllSecReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this.isShowLoaderIndicator = false;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const sectionLstObj = response.data[0] || [];
						this.royaltiesRptTabs.forEach(element => {
							switch (element.key) {
								case RoyaltiesSettlementReportSectionEnum.INVOICES:
									element.value = sectionLstObj.hasOwnProperty('invoices') ? sectionLstObj['invoices'] : 0;
									break;
								case RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES:
									element.value = sectionLstObj.hasOwnProperty('extraCommissionBasedRules') ? sectionLstObj['extraCommissionBasedRules'] : 0;
									break;
								case RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS:
									element.value = sectionLstObj.hasOwnProperty('amortizedPayments') ? sectionLstObj['amortizedPayments'] : 0;
									break;
								// case RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS:
								// 	element.value = sectionLstObj.hasOwnProperty('serviceArcreditmemos') ? sectionLstObj['serviceArcreditmemos'] : 0;
								// 	break;
								default:
									break;
							}
						});

					}

				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this.isShowLoaderIndicator = false;
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
}
