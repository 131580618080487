import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownButtonComponent } from 'devextreme-angular';
import { Observable, Subscription } from 'rxjs';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { ViewPopUpModel } from '@app/models/popup.model';
import * as _ from 'underscore';
@Component({
	selector: 'app-cts-change-order-rpt-tab',
	templateUrl: './cts-change-order-rpt-tab.component.html',
	styleUrls: ['./cts-change-order-rpt-tab.component.css']
})
export class CtsChangeOrderRptTabComponent implements OnInit, OnDestroy {
	@ViewChild("changeOrderDataGridRef", {}) changeOrderDataGridRef: DxDataGridComponent;
	@ViewChild("dropDownButtonRef", {}) dropDownButtonRef: DxDropDownButtonComponent;
	@Input() commissionRptDetails: CommissionSettlemntRptModel;
	@Input() viewPopUpModelProps: ViewPopUpModel;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public CommissionSettlementReportSectionEnum = CommissionSettlementReportSectionEnum;
	private updateUserSettingSbsn: Subscription;
	public changeOrdersDataSource: any = [];
	public filterValue: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string = '';
	public listOfActions: any[] = [];
	public listOfFeatureCustomFields = [];
	private guid = new Guid();
	private filterValueChangeSbsn: Observable<any>;
	public stateStorageKey = 'changeOrders-datagrid-state';
	public globalCurrencySymbol: string;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfCommissionBasis: any[] = [];
	public listOfCommissionBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'changeOrder', text: 'ChangeOrder' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'order', text: 'Order' }];
	public pageOptions: PageOptions = new PageOptions();
	public enable_profit_on_document_header: boolean = false;
	public currencyFormat: any;
	@Input() grdDataSource?: any; // getting from commission contract report 
	public decimalPointForCurrency?: number;

	constructor(private _AppCommonSrvc: AppCommonSrvc,
		private _CommissionService: CommissionService,
		private _LoginService: LoginService,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe) {
		this.doLoadChangeOrderState = this.doLoadChangeOrderState.bind(this);
		this.doSaveChangeOrderState = this.doSaveChangeOrderState.bind(this);
		this.doCustomizeTextToPercent = this.doCustomizeTextToPercent.bind(this);
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
	}

	ngOnInit(): void {
		this.listOfActions = this._CommissionService.getListOfActionsDatagrid();
		this.listOfFeatureCustomFields = this._CommissionService.getlistOfFeatureCustomFields();
		this.currencyFormat = this.commissionRptDetails.currencyFormat;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.pageOptions.pageSize = PAGE_SIZE_OPTIONS[1];
		this.enable_profit_on_document_header = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_profit_on_document_header')) {
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
		}
		if (this.commissionRptDetails.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		} else {
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		}
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
		if (this.grdDataSource) {
			this.changeOrdersDataSource = this.grdDataSource;
			this.updateStatesClearBtn();
		} else {
			this.loadChangeOrdersDataSource();
		}
		setTimeout(() => {
			if (this.changeOrderDataGridRef) {
				this.filterValueChangeSbsn = this.changeOrderDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextTab();
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	public initializedChangeOrderDataGrid(event) {
		if (this.enable_profit_on_document_header) {
			//setTimeout(() => {
			const customFieldDataList = this._CommissionService.getCustomField(this.listOfFeatureCustomFields, this.commissionRptDetails.currency_code);
			const listOfColumns = this.changeOrderDataGridRef.instance.getVisibleColumns();
			customFieldDataList.forEach(element => {
				const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
				if (columnIndex === -1) {
					this.changeOrderDataGridRef.instance.addColumn(element);
				}
			});
			// 	customFieldDataList.forEach(element => {
			// 		event.component.addColumn(element);
			// 	});
			//}, 200);

		}
	}
	public doOnChangeOrderCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				this.viewPopUpModelProps.docNo = element.data[element.column.dataField];
				this.viewPopUpModelProps.docType = 'change_order';
				this.viewPopUpModelProps.detailView = '';
				setTimeout(() => {
					this.viewPopUpModelProps.detailView = 'change_order';
				}, 300);
			}
		}
	}
	public doCustomizeTotalSummary(e) {
		if (e.valueText) {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		}
	}

	doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}

	doChangeOrderToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.changeOrderDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateActionBtns',
			// 	visible: true,
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			// columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	doActionsBtnItemClick(event) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.changeOrderDataGridRef.instance.showColumnChooser();
					break;
			}
		}
	}
	doRefreshDataGrid() {
		this.changeOrderDataGridRef.instance.refresh();
	}
	doExportToExcel() {

	}
	doActionsBtnClick(e: any) {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		// }
	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.changeOrderDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextTab() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		if (this.changeOrderDataGridRef) {
			this.changeOrderDataGridRef.filterPanel.customizeText = (e: any) => {
				this.filterText = e.text;
				if (this.filterText) {
					this.isDisabledClearBtn = false;
				} else {
					this.isDisabledClearBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}
	// Method used to Enabled/Disabled Clear Button
	public updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
	public doLoadChangeOrderState = () => {
		return this._CommissionService.loadStateDataGrid(this.stateStorageKey);
	}
	public doSaveChangeOrderState = (e) => {
		this._CommissionService.saveStateDataGrid(e, this.stateStorageKey);
	}
	loadChangeOrdersDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.CHANGE_ORDERS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.commissionRptDetails.contractGuid,
		}
		this.changeOrdersDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onLoaded: (result) => {
				this.updateStatesClearBtn();
			}
		});

	}
}
