import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownButtonComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { ViewPopUpModel } from '@app/models/popup.model';
import { Router } from '@angular/router';
@Component({
	selector: 'app-cts-invoice-rpt-tab',
	templateUrl: './cts-invoice-rpt-tab.component.html',
	styleUrls: ['./cts-invoice-rpt-tab.component.css']
})
export class CtsInvoiceRptTabComponent implements OnInit, OnDestroy {
	@ViewChild("invoiceDataGridRef", {}) invoiceDataGridRef: DxDataGridComponent;
	@ViewChild("dropDownButtonRef", {}) dropDownButtonRef: DxDropDownButtonComponent;
	@Input() commissionRptDetails: CommissionSettlemntRptModel;
	@Input() viewPopUpModelProps: ViewPopUpModel;
	@Input() grdDataSource?: any; // getting from commission contract report 
	public pageOptions: PageOptions = new PageOptions();
	public estimatedRebateTabData: any[] = [];
	public isShowEstRebPopup: boolean = false;
	public isviewRebateCalView: boolean = false;
	public estimatedRebateHeaderText: string = 'Estimated Rebate Calculation';
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public CommissionSettlementReportSectionEnum = CommissionSettlementReportSectionEnum;
	private dataOutReqSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	private getUomLstSbsn: Subscription;
	public invoicesDataSource: any = [];
	public filterValue: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string = '';
	public listOfActions: any[] = [];
	public listOfFeatureCustomFields = [];
	private guid = new Guid();
	private filterValueChangeSbsn: Observable<any>;
	public stateStorageKey = 'invoices-datagrid-state';
	public globalCurrencySymbol: string;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfCommissionBasis: any[] = [];
	public listOfCommissionBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'order', text: 'Order' }];
	public erp_type: string;
	public ErpTypeValEnum = ErpTypeValEnum;
	public isQBOnlineErpUser: boolean = false;
	public isCustomerAgeTiers: boolean = false;
	public enable_classification_for_commision: boolean = false;
	public enable_invoice_custom_fields_feature: boolean = false;
	public currencyFormat: any;
	public decimalPointForCurrency: number;
	public decimalPointPercentageFormat: string = '1.2-2';
	public enable_incentive_accrual_journal_entry: boolean = false;
	public pageFromMainModule: string;
	public isShowColumnsForReportView: boolean;

	constructor(private _Router: Router,
		private _AppCommonSrvc: AppCommonSrvc,
		private _CommissionService: CommissionService,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _RebateService: RebateService,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
	) {
		this.enable_incentive_accrual_journal_entry = this._AppCommonSrvc.getEnableIncentiveAccualJournalEntery();
		this.doConvertPercentValue = this.doConvertPercentValue.bind(this);
		this.doCustomizeTextToPercent = this.doCustomizeTextToPercent.bind(this);
		this.doCustomizeTextToDollar = this.doCustomizeTextToDollar.bind(this);
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.doCalculateCellValueOrgCommissionAmount = this.doCalculateCellValueOrgCommissionAmount.bind(this);
		this.doLoadInvoiceState = this.doLoadInvoiceState.bind(this);
		this.doSaveInvoiceState = this.doSaveInvoiceState.bind(this);
		this.doCustomizeColumnsDataGrid = this.doCustomizeColumnsDataGrid.bind(this);

		const currentUrl = this._Router.url;
		if (currentUrl.startsWith('/contracts/commission/modern-report')) {
			this.pageFromMainModule = 'CONTRACT_COMMISSIONS';
		}
		if (currentUrl.startsWith('/reports/incentivesreports')) {
			this.pageFromMainModule = 'INCENTIVES_REPORT';
		}
		if (currentUrl.startsWith('/notifications/approvals/commission-modern-report')) {
			this.pageFromMainModule = 'NOTIFICATIONS_APPROVALS';
		}
	}

	ngOnInit(): void {
		this.listOfActions = this._CommissionService.getListOfActionsDatagrid();
		this.listOfFeatureCustomFields = this._CommissionService.getlistOfFeatureCustomFields();
		this.currencyFormat = this.commissionRptDetails.currencyFormat;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.pageOptions.pageSize = PAGE_SIZE_OPTIONS[1];
		if (this.commissionRptDetails.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		} else {
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		}
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
		}
		this.isCustomerAgeTiers = false;
		if (this._LoginService.loginUser.account_detail.app_settings.hasOwnProperty('customer_age_tiers')) {
			this.isCustomerAgeTiers = this._LoginService.loginUser.account_detail.app_settings.customer_age_tiers;
		}
		this.enable_classification_for_commision = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_classification_for_commision')) {
			this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision;
		}
		this.enable_invoice_custom_fields_feature = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_invoice_custom_fields_feature')) {
			this.enable_invoice_custom_fields_feature = this._LoginService.loginUser.account_detail.enable_invoice_custom_fields_feature;
		}
		this.doHideShowColumns();
		this.fetchListOfUoms();
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
		this.listOfTierBasis = this._CommissionService.getTierBasis();
		this.listOfCommissionBasis = this._CommissionService.getCommissionBasis();
		this.listOfTierBasisHeaderFilter = this.getHeaderFilter(this.listOfTierBasis, 'code', 'label');
		this.listOfCommissionBasisHeaderFilter = this.getHeaderFilter(this.listOfCommissionBasis, 'code', 'label');
		if (this.grdDataSource) {
			this.invoicesDataSource = this.grdDataSource;
			this.updateStatesClearBtn();
		} else {
			this.loadInvoicesDataSource();
		}
		setTimeout(() => {
			if (this.invoiceDataGridRef) {
				this.filterValueChangeSbsn = this.invoiceDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextTab();
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
	}

	public initializedInvoiceDataGrid(event) {
		if (this.enable_invoice_custom_fields_feature) {
			const customFieldDataList = this._CommissionService.getCustomField(this.listOfFeatureCustomFields, this.commissionRptDetails.currency_code);
			const listOfColumns = this.invoiceDataGridRef.instance.getVisibleColumns();
			customFieldDataList.forEach(element => {
				const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
				if (columnIndex === -1) {
					this.invoiceDataGridRef.instance.addColumn(element);
				}
			});
		}
	}

	public doCustomizeColumnsDataGrid(e: any) {
		const listOfColumns = e;
		const dataFields = ['accrual_commissionAmount', 'customer_age', 'docduedate'];
		dataFields.forEach(df => {
			const columnIndex = listOfColumns.findIndex(col => col.dataField === df);
			if (columnIndex !== -1) {
				if (this.enable_incentive_accrual_journal_entry && df === 'accrual_commissionAmount') {
					listOfColumns[columnIndex].visible = true;
				} else if (this.isCustomerAgeTiers && df === 'customer_age') {
					listOfColumns[columnIndex].visible = true;
				} else if (this.enable_classification_for_commision && df === 'classname') {
					listOfColumns[columnIndex].visible = true;
				} else if (!this.enable_classification_for_commision && (df === 'description' || df === 'itemname')) {
					listOfColumns[columnIndex].visible = true;
				} else if (this.erp_type === ErpTypeValEnum.EPICOR_PROPHET21 && df === 'branch') {
					listOfColumns[columnIndex].visible = true;
				} else {
					listOfColumns[columnIndex].visible = false;
				}
			}
		});
	}

	doInvoiceToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.invoiceDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateActionBtns',
			// 	visible: true,
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			// columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}

	}

	public doOnEditorPreparingForInvoice(event) {
		if (event && event['parentType'] && event['parentType'] === "dataRow") {
			const itemObject = event.row.data || [];

			if (event.dataField === 'contract_selectedUom' || event.dataField === 'contract_quantity') {
				if (itemObject.commission_basis === 'dollarperuom') {
					event.editorOptions.visible = true;
					event.editorOptions.showInColumnChooser = true;
				} else {
					event.editorOptions.visible = false;
					event.editorOptions.showInColumnChooser = false;
				}
			}
			if (event.dataField === 'miscDedAmount' || event.dataField === 'miscDedFcAmount' || event.dataField === 'cashdiscount' || event.dataField === 'finalAmount') {
				if (itemObject.commission_basis === 'percentage') {
					event.editorOptions.visible = true;
					event.editorOptions.showInColumnChooser = true;
				} else {
					event.editorOptions.visible = false;
					event.editorOptions.showInColumnChooser = false;
				}
			}

			if (event.dataField === 'grossprofit' || event.dataField === 'costtotal') {
				if (itemObject.calculationOnBasis === 'grossprofit') {
					event.editorOptions.visible = true;
					event.editorOptions.showInColumnChooser = true;
				} else {
					event.editorOptions.visible = false;
					event.editorOptions.showInColumnChooser = false;
				}
			}
		}
	}

	public doOnCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && element.column.dataField === 'rebate_amount') {
				let rebateCalculationTabsList: any = [];
				if (element.data && element.data['rebateCalculation'] && element.data['rebateCalculation'].length > 0) {
					element.data['rebateCalculation'].forEach(element => {
						const objTab = {
							id: element.guid,
							text: element.contractName,
							//icon: 'user',
						}
						rebateCalculationTabsList.push(objTab);
					});
					this.estimatedRebateTabData = rebateCalculationTabsList;
					this.doOpenPopup(element);
				}
			}
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				this.viewPopUpModelProps.docNo = element.data[element.column.dataField];
				this.viewPopUpModelProps.docType = 'invoice';
				this.viewPopUpModelProps.detailView = '';
				setTimeout(() => {
					this.viewPopUpModelProps.detailView = 'invoice';
				}, 300);
			}
		}
	}

	doActionsBtnItemClick(event) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.invoiceDataGridRef.instance.showColumnChooser();
					break;
			}
		}
	}
	doRefreshDataGrid() {
		this.invoiceDataGridRef.instance.refresh();
	}
	doExportToExcel() {

	}
	doActionsBtnClick(e: any) {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		// }
	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.invoiceDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextTab() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		if (this.invoiceDataGridRef) {
			this.invoiceDataGridRef.filterPanel.customizeText = (e: any) => {
				this.filterText = e.text;
				if (this.filterText) {
					this.isDisabledClearBtn = false;
				} else {
					this.isDisabledClearBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}
	// Method used to Enabled/Disabled Clear Button
	public updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
	public doLoadInvoiceState = () => {
		const statePersistent = this._CommissionService.loadStateDataGrid(this.stateStorageKey);
		if (statePersistent && statePersistent.columns && statePersistent.columns.length > 0) {
			statePersistent.columns.forEach(col => {
				if (col.dataField === 'contractName' || col.dataField === 'subusername' || col.dataField === 'settlement_number' || col.dataField === 'status' || col.dataField === 'errorMessage' || col.dataField === 'settlementPeriodStart' || col.dataField === 'settlementPeriodEnd') {
					col.visible = this.isShowColumnsForReportView;
				}
			});
		}
		return statePersistent || null;
	}
	public doSaveInvoiceState = (e) => {
		this._CommissionService.saveStateDataGrid(e, this.stateStorageKey);
	}
	loadInvoicesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.INVOICES,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.commissionRptDetails.contractGuid,
		}
		this.invoicesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onLoaded: (result) => {
				this.updateStatesClearBtn();
			}
		});
	}

	doConvertPercentValue(value: any) {
		return this._CustomNumberNoRoundingPipe.transform(+value || 0) + '%';
	}
	doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}
	doCustomizeTextToDollar(cellInfo) {
		return this._CommissionService.convertCurrency((+cellInfo.value || 0), this.customCurrencyOption);
	}
	// Method used to display zero if negative summary value
	public doCustomizeTotalSummary(e) {
		if (e.valueText) {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		}
	}

	public doCalculateCellValueOrgCommissionAmount(rowData?: any) {
		let docCurrencyName = rowData.doccurrency || '';
		let customCurrencyOption: CurrencyOptions = new CurrencyOptions();
		if (rowData.doccurrency) {
			customCurrencyOption.currencyCode = this.customCurrencyOption.currencyCode;
			customCurrencyOption.currencyName = docCurrencyName;
			if (rowData.orgCommissionAmount && rowData.orgCommissionAmount > 0) {
				return this._CommissionService.convertCurrency(rowData.orgCommissionAmount, customCurrencyOption);
			} else {
				return this._CommissionService.convertCurrency(0, customCurrencyOption);
			}
		}
	}
	public doOpenPopup(element) {
		this.isShowEstRebPopup = true;
		const selectedTabCode = this.estimatedRebateTabData[0].id;
		this.getRebatesContractDetails(selectedTabCode);
	}
	private getRebatesContractDetails(rebatesGuid: any) {
		this._RebateService.setActionFrom('rebates');
		if (rebatesGuid) {
			this.featchRebateContract(rebatesGuid);
		}
		//this._RebateService.setEditRebatesGuid(rebatesGuid);
	}
	public featchRebateContract(rebateGuid) {
		this.isviewRebateCalView = false;
		const formData = new FormData();
		const searchParams = [{ 'guid': rebateGuid }];
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('guid', rebateGuid);
		formData.append('settlementPeriodStart', this.commissionRptDetails.settlementPeriodStartDate);
		formData.append('settlementPeriodEnd', this.commissionRptDetails.settlementPeriodEndDate);
		formData.append('search', JSON.stringify(searchParams));
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response['data'] && response['data'].length > 0) {
							response.data[0]['settlementPeriodStart'] = this.commissionRptDetails.settlementPeriodStartDate;
							response.data[0]['settlementPeriodEnd'] = this.commissionRptDetails.settlementPeriodEndDate;
							this._RebateService.setInvoiceDetails(response.data[0] || [])
							this.isviewRebateCalView = true;
						}
					}
				} else {
					this._ToastrService.info(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	public doHiddenEstRebatePopop(e) {
		this.isShowEstRebPopup = false;
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
						this.listOfUomsHeaderFilter = this.getHeaderFilter(response.data, 'code', 'name');
					}
				}
			}, error: (error) => {
				// this._LoaderService.hide();
			}
		});
	}

	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			listOfHeaderFilters.push({ value: ele[codeField], text: ele[textField].toString().toUpperCase() });
		});
		return listOfHeaderFilters;
	}
	// #endregion

	private doHideShowColumns() {
		this.isShowColumnsForReportView = false;
		if (this.pageFromMainModule === 'INCENTIVES_REPORT') {
			this.isShowColumnsForReportView = true;
		}
		setTimeout(() => {
			if (this.invoiceDataGridRef) {
				this.invoiceDataGridRef.instance.columnOption('contractName', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('contractName', 'showInColumnChooser', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('subusername', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('subusername', 'showInColumnChooser', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('settlement_number', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('settlement_number', 'showInColumnChooser', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('status', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('status', 'showInColumnChooser', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('errorMessage', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('errorMessage', 'showInColumnChooser', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('settlementPeriodStart', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('settlementPeriodStart', 'showInColumnChooser', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('settlementPeriodEnd', 'visible', this.isShowColumnsForReportView);
				this.invoiceDataGridRef.instance.columnOption('settlementPeriodEnd', 'showInColumnChooser', this.isShowColumnsForReportView);
			}
		}, 50);
	}
}
